<script lang="ts">
	export let height = '48px' // Default height
	export let width = '48px' // Default width
	let className = ''
	export { className as class }
</script>

<svg
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M39 19C39.0014 16.2739 38.2599 13.599 36.8551 11.2627C35.4504 8.92638 33.4355 7.01708 31.027 5.73999C28.6186 4.46289 25.9076 3.8663 23.1855 4.01432C20.4635 4.16234 17.8332 5.04939 15.5774 6.58011C13.3216 8.11084 11.5256 10.2274 10.3825 12.7022C9.23937 15.1771 8.79228 17.9167 9.08931 20.6265C9.38633 23.3364 10.4162 25.9141 12.0683 28.0826C13.7204 30.251 15.9322 31.9283 18.466 32.934L24 44L29.534 32.934C32.3254 31.826 34.72 29.9056 36.4077 27.4213C38.0954 24.937 38.9984 22.0033 39 19Z"
		fill="currentColor"
	/>
	<path
		d="M24 5C26.7689 5 29.4756 5.8211 31.7779 7.35946C34.0802 8.89782 35.8747 11.0844 36.9343 13.6424C37.9939 16.2007 38.2711 19.0156 37.7309 21.7314C37.1907 24.4471 35.8575 26.9417 33.8993 28.8996C31.9414 30.8575 29.4469 32.1907 26.7311 32.7309C24.0156 33.2713 21.2007 32.9939 18.6424 31.9343C16.0841 30.8747 13.8978 29.0802 12.3595 26.7781C10.8211 24.4758 10 21.7689 10 19C10 15.2869 11.4749 11.726 14.1006 9.10059C16.726 6.47513 20.2869 5 24 5Z"
		fill="currentColor"
	/>
	<path
		d="M25.2083 12.425C25.0933 12.425 25 12.5183 25 12.6333V12.7875C25 13.9381 25.9327 14.8708 27.0833 14.8708C28.2339 14.8708 29.1667 13.9381 29.1667 12.7875V12.6333C29.1667 12.5183 29.0734 12.425 28.9583 12.425H25.2083Z"
		fill="white"
	/>
	<path
		d="M19.4617 23.97L20.1825 22.3758C20.1906 22.2452 20.1369 22.1184 20.0375 22.0333L18.3375 20.575C18.1053 20.3737 17.922 20.122 17.8017 19.8392C17.7581 19.74 17.6458 19.6907 17.5433 19.7258C16.0271 20.2379 15.0047 21.6579 15 23.2583V26.175C15 26.4051 15.1865 26.5917 15.4167 26.5917H19.6908C19.774 26.5919 19.8493 26.5426 19.8824 26.4664C19.9156 26.3901 19.9002 26.3015 19.8433 26.2408C19.2686 25.6307 19.118 24.7345 19.4617 23.97Z"
		fill="white"
	/>
	<path
		d="M34.6083 18.1417L31.775 16.3608V15.1108C31.7754 14.9235 31.6747 14.7505 31.5116 14.6583C31.3487 14.5644 31.1473 14.5676 30.9875 14.6667C29.784 15.4697 28.6838 16.4177 27.7116 17.4892C27.6713 17.5334 27.652 17.5928 27.6585 17.6523C27.665 17.7118 27.6968 17.7656 27.7458 17.8L28.6825 18.4608C29.6754 19.0426 30.0088 20.3191 29.4271 21.3121C28.8453 22.305 27.5688 22.6384 26.5758 22.0567C26.5533 22.0433 24.7683 20.7842 24.7683 20.7842C24.5319 20.6452 24.3087 20.4848 24.1016 20.305C24.0304 20.2431 23.9264 20.2369 23.8482 20.2898C23.77 20.3428 23.7373 20.4417 23.7683 20.5308C24.1733 21.5048 24.3139 22.5684 24.1758 23.6142C24.1479 23.7101 24.1134 23.8039 24.0725 23.895L23.2625 25.6875C23.17 25.8922 23.0441 26.08 22.89 26.2433C22.8331 26.304 22.8177 26.3926 22.8509 26.4689C22.884 26.5451 22.9593 26.5944 23.0425 26.5942H29.5833C29.8134 26.5942 30 26.4076 30 26.1775V24.9275C30.0086 23.1547 30.5578 21.4267 31.5741 19.9742L33.3125 20.2958C33.8349 20.4467 34.3947 20.2408 34.695 19.7875C34.7125 19.7608 34.945 19.1425 34.945 19.1425C35.0854 18.7713 34.9446 18.3525 34.6083 18.1417Z"
		fill="white"
	/>
	<path
		d="M22.4999 25.3417C22.4999 25.3417 23.3549 23.4375 23.3724 23.3792C23.7724 21.9697 23.317 20.4564 22.2058 19.5017L21.6524 19.0275L23.3583 17.6667L23.6316 18.305C23.955 19.052 24.5101 19.6752 25.2149 20.0825L26.9899 21.3333C27.3779 21.5548 27.8545 21.5526 28.2403 21.3273C28.6261 21.1021 28.8624 20.6882 28.8603 20.2415C28.8582 19.7948 28.6179 19.3832 28.2299 19.1617L26.4591 17.9117C26.2232 17.7761 26.0373 17.568 25.9291 17.3183L25.0099 15.1667C24.8555 14.8092 24.5434 14.5442 24.1656 14.4497C23.7879 14.3553 23.3877 14.4423 23.0833 14.685L18.9166 18.0183C18.626 18.2509 18.4541 18.601 18.4478 18.9732C18.4415 19.3454 18.6015 19.701 18.8841 19.9433L20.5833 21.4008C20.9331 21.7006 21.0897 22.1684 20.9908 22.6183L20.2249 24.3133C19.9829 24.9328 20.2683 25.6334 20.8744 25.9073C21.4804 26.1812 22.1948 25.9326 22.4999 25.3417V25.3417Z"
		fill="white"
	/>
	<path
		d="M30.4167 11.5917C30.6468 11.5917 30.8334 11.4051 30.8334 11.175C30.8334 10.9449 30.6468 10.7583 30.4167 10.7583H29.4167C29.0955 10.1426 28.5339 9.68712 27.865 9.5C26.6171 9.14362 25.3053 9.80862 24.855 11.0258C24.8069 11.1538 24.8246 11.2973 24.9025 11.4097C24.9803 11.5221 25.1083 11.5892 25.245 11.5892L30.4167 11.5917Z"
		fill="white"
	/>
</svg>
