<script lang="ts">
	export let height = '48px' // Default height
	export let width = '48px' // Default width
	let className = ''
	export { className as class }
</script>

<svg
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M39 19C39.0014 16.2739 38.2599 13.599 36.8551 11.2627C35.4504 8.92638 33.4355 7.01708 31.027 5.73999C28.6186 4.46289 25.9076 3.8663 23.1855 4.01432C20.4635 4.16234 17.8332 5.04939 15.5774 6.58011C13.3216 8.11084 11.5256 10.2274 10.3825 12.7022C9.23937 15.1771 8.79228 17.9167 9.08931 20.6265C9.38633 23.3364 10.4162 25.9141 12.0683 28.0826C13.7204 30.251 15.9322 31.9283 18.466 32.934L24 44L29.534 32.934C32.3254 31.826 34.72 29.9056 36.4077 27.4213C38.0954 24.937 38.9984 22.0033 39 19Z"
		fill="currentColor"
	/>
	<path
		d="M24 5C26.7689 5 29.4756 5.8211 31.7779 7.35946C34.0802 8.89782 35.8747 11.0844 36.9343 13.6424C37.9939 16.2007 38.2711 19.0156 37.7309 21.7314C37.1907 24.4471 35.8575 26.9417 33.8993 28.8996C31.9414 30.8575 29.4469 32.1907 26.7311 32.7309C24.0156 33.2713 21.2007 32.9939 18.6424 31.9343C16.0841 30.8747 13.8978 29.0802 12.3595 26.7781C10.8211 24.4758 10 21.7689 10 19C10 15.2869 11.4749 11.726 14.1006 9.10059C16.726 6.47513 20.2869 5 24 5Z"
		fill="currentColor"
	/>
	<path
		d="M27.735 19.8008C27.4022 19.7509 27.0805 19.6438 26.7842 19.4842C26.6083 19.3892 26.3897 19.4326 26.2633 19.5875C25.7095 20.2689 24.8781 20.6645 24 20.6645C23.1219 20.6645 22.2905 20.2689 21.7367 19.5875C21.6103 19.4326 21.3918 19.3892 21.2158 19.4842C20.9195 19.6438 20.5978 19.7509 20.265 19.8008C20.2024 19.8095 20.1471 19.8461 20.1147 19.9004C20.0823 19.9547 20.0762 20.0208 20.0983 20.08L23.2158 28.4558C23.3371 28.7824 23.6487 28.9992 23.9971 28.9992C24.3455 28.9992 24.6571 28.7824 24.7783 28.4558L27.8992 20.0833C27.9208 20.0242 27.915 19.9586 27.8833 19.9042C27.8523 19.8489 27.7976 19.8108 27.735 19.8008Z"
		fill="white"
	/>
	<path
		d="M24 9C20.7798 9.00367 18.1703 11.6132 18.1666 14.8333V16.9167C18.1666 17.8371 18.9128 18.5833 19.8333 18.5833C20.7538 18.5833 21.5 17.8371 21.5 16.9167C21.5 16.6865 21.6865 16.5 21.9166 16.5C22.1467 16.5 22.3333 16.6865 22.3333 16.9167V17.75C22.3333 18.6705 23.0795 19.4167 24 19.4167C24.9204 19.4167 25.6666 18.6705 25.6666 17.75V16.9167C25.6666 16.6865 25.8532 16.5 26.0833 16.5C26.3134 16.5 26.5 16.6865 26.5 16.9167C26.5 17.8371 27.2462 18.5833 28.1666 18.5833C29.0871 18.5833 29.8333 17.8371 29.8333 16.9167V14.8333C29.8296 11.6132 27.2201 9.00367 24 9Z"
		fill="white"
	/>
</svg>
